import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PrismicRichText, usePrismicDocumentsByType } from '@prismicio/react';
import * as RichText from '@prismicio/richtext';

import { Helmet } from 'react-helmet-async';
import { JobsDocument } from '../Vendors/prismic.types.generated';
import { BASE_URL } from '../constant';

function Jobs() {
  const [entries] = usePrismicDocumentsByType<JobsDocument>('jobs');
  const title = 'Join Our Team';
  const description = 'Current job openings';
  return (
    <div className="page jobs">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`${BASE_URL}/jobs`} />
      </Helmet>
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <h1>{title}</h1>
            {entries?.results.map((entry) => (
              <div key={entry.id} className="job-entry">
                <h3>
                  {RichText.asText(entry.data.title)}
                  {`: ${entry.data.department} - ${entry.data.type}`}
                </h3>
                <hr className="heading-line" />
                <PrismicRichText field={entry.data.job_description} />
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Jobs;
