import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Helmet } from 'react-helmet-async';
import pricing from './data/pricing.json';
import { BASE_URL, SITE_NAME } from '../../constant';

function Prices() {
  const pricingByType: {
    [key: string]: {
      Type: string;
      Description: string;
      Price: number;
      Tax: number | string;
      Comments: string;
    }[]
  } = {};
  pricing.forEach((row) => {
    if (pricingByType[row.Type] === undefined) pricingByType[row.Type] = [];
    pricingByType[row.Type].push(row);
  });
  const cols = [
    ['Recording Charges', 'Lien Searches'],
    ['Ancillary Charges'],
  ];
  // console.log(slice);
  const title = 'Prices';

  return (
    <div className="page prices">
      <Helmet>
        <title>{`${title} - ${SITE_NAME}`}</title>
        <meta property="og:title" content={title} />
        <meta property="og:url" content={`${BASE_URL}/prices`} />
      </Helmet>
      <Container>
        <Row className="justify-content-center">
          <Col lg={10} xl={8}>
            <h1>{title}</h1>
            <Row>
              {cols.map((headings, j) => (
                // eslint-disable-next-line react/no-array-index-key
                <Col lg={6} key={j}>
                  {headings.map((heading) => (
                    <div key={heading} className="price-group">
                      <h2>{heading}</h2>
                      <table>
                        <tbody>
                          {pricingByType[heading].map((row) => (
                            <tr key={row.Description}>
                              <td>
                                <div className="description">{row.Description}</div>
                                <div className="comments">{row.Comments}</div>
                              </td>
                              <td>
                                <div className="price">{`$${row.Price.toLocaleString()}`}</div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Prices;
