import { PrismicRichText, usePrismicDocumentByUID } from '@prismicio/react';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet-async';
import { asText } from '@prismicio/richtext';
import { BASE_URL } from '../constant';

import { ContactUsSettingsDocument } from '../Vendors/prismic.types.generated';
import BasicContactForm from './Contact/BasicContactForm';
import Loading from '../Layout/Loading';

export default function Location() {
  const [document] = usePrismicDocumentByUID<ContactUsSettingsDocument>('contact_us_settings', 'contact-us');
  if (document === undefined) return (<Loading />);
  const title = asText(document.data.title);
  const description = asText(document.data.seo_description);
  const image = `${document?.data.seo_image.url}&width=1200`;
  return (
    <div className="page location">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={`${BASE_URL}/locations`} />
      </Helmet>
      <Container>
        <h1>{title}</h1>
        <Row className="justify-content-center">
          <Col lg={8}>
            <Row>
              {document?.data.locations.map((location) => (
                <Col key={location.map_url} className="address">
                  <PrismicRichText field={location.address} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <div className="gallery">
          <Row>
            {document?.data.office_gallery.map((photoRow) => (
              <Col key={photoRow.photo.url} lg={3} xs={6}>
                <img src={`${photoRow.photo.url}&width=480&height=270&fit=crop`} alt="office" className="photo" />
              </Col>
            ))}
          </Row>
        </div>
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="heading-with-line">
              <h2>{asText(document.data.contact_us_title)}</h2>
              <hr />
            </div>
            <p>{asText(document.data.sidebar_top)}</p>
            <BasicContactForm context="Website Contact Us (location page)" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
