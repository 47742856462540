import { CLOSING_BUY, CLOSING_REFI, REFI_CEMA } from '../calcConstants';
import { ModelRateInputData } from '../rateInputData.model';

export function validateInput(data: ModelRateInputData) {
  let isValid = true;
  const errors: {
    purchase?: string
    loan?: string
    loanOld?: string
    county?: string
  } = {};
  if (data.closingType === CLOSING_BUY) {
    if (data.purchase === '') {
      isValid = false;
      errors.purchase = 'Purchase price is required.';
    }
  } else if (data.closingType === CLOSING_REFI) {
    if (data.loan === '') {
      isValid = false;
      errors.loan = `${data.closingType === CLOSING_REFI && data.refiType === REFI_CEMA ? 'New Money' : 'Loan Amount'} is required.`;
    }
    if (data.refiType === REFI_CEMA) {
      if (data.loanOld === '') {
        isValid = false;
        errors.loanOld = 'Principal Outstanding is required.';
      }
    }
  }
  if (data.county === '') {
    isValid = false;
    errors.county = 'County is required';
  }
  return {
    isValid,
    errors,
  };
}
