import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ThankYou() {
  return (
    <div className="page thankyou">
      <Row className="justify-content-center">
        <Col lg={6}>
          <h1>Thank You</h1>
          <p>
            Thank you for signing up for our newsletter. Please be
            on the look out for upcoming e-CLE classes, as well as,
            news and updates from Liberty Land Abstract.
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default ThankYou;
