import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Helmet } from 'react-helmet-async';
import { BASE_URL } from '../constant';
import RateCalculator from '../RateCalculator/RateCalculator';

function Calculator() {
  const title = 'Rate Calculator';
  const description = 'Use our rate calculator to get an estimated invoice';
  return (
    <div className="page calculator">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`${BASE_URL}/calculator`} />
      </Helmet>
      <Container>
        <Row className="justify-content-center">
          <Col lg={6}>
            <h1>{title}</h1>
            <RateCalculator />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Calculator;
