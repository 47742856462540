import { PrismicLink } from '@prismicio/react';
import React from 'react';

import { DocumentsDocument } from '../../Vendors/prismic.types.generated';

function DocGroup({ groupName, entries }: {
  groupName: string,
  entries: DocumentsDocument[] | undefined,
}) {
  if (entries === undefined || entries.length === 0) return null;
  return (
    <div className="doc-group">
      <h2>{groupName}</h2>
      {entries.map((entry) => (
        <div className="doc-row" key={entry.id}>
          <div className="title">
            {entry.data.title}
          </div>
          <div className="download">
            {entry.data.document_pdf ? (
              <PrismicLink field={entry.data.document_pdf}>
                <span className="fas fa-file-pdf" />
              </PrismicLink>
            ) : null}
            {' '}
            {entry.data.document_doc ? (
              <PrismicLink field={entry.data.document_doc}>
                <span className="fas fa-file-word" />
              </PrismicLink>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
}

const memDocGroup = React.memo(DocGroup);
export default memDocGroup;
