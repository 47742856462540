import loadjs from 'loadjs';
import React, { useEffect } from 'react';
import { RECAPTCHA_SITE_KEY } from '../../Vendors/recaptch';

function BasicContactForm({ context }: { context: string }) {
  useEffect(() => {
    loadjs('https://www.google.com/recaptcha/api.js', () => {});
  }, []);
  return (
    <form
      acceptCharset="UTF-8"
      action="https://usebasin.com/f/2bc316e0e8c7"
      method="POST"
      className="basic-contact-form"
    >
      <input type="hidden" name="test" />
      <input type="hidden" name="context" value={context || 'Website Contact Form'} />

      <label>Your name</label>
      <input type="text" name="your-name" />

      <label>Your email</label>
      <input type="text" name="your-email" />

      <label>Message</label>
      <textarea name="Message" />
      <div className="g-recaptcha" data-sitekey={RECAPTCHA_SITE_KEY} />
      <input type="submit" className="btn btn-primary" value="Contact us" />
    </form>
  );
}

export default BasicContactForm;
