import { PrismicRichText, usePrismicDocumentByUID } from '@prismicio/react';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

import { Link } from 'react-router-dom';
import Newsletter from '../Newsletter/Newsletter';

import { ContactUsSettingsDocument } from '../Vendors/prismic.types.generated';

export default function Footer() {
  const [document] = usePrismicDocumentByUID<ContactUsSettingsDocument>('contact_us_settings', 'contact-us');
  // console.log(document);
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col md={{ span: 3, order: 1 }} xs={{ span: 6, order: 3 }}>
            <img src="/images/LLA-logo (stacked).png" alt="Liberty Land Abstract" className="footer-logo" />
          </Col>
          <Col md={{ span: 3, order: 2 }} xs={{ span: 6, order: 1 }}>
            {document?.data.locations.map((location) => (
              <div key={location.map_url} className="address">
                <PrismicRichText field={location.address} />
              </div>
            ))}
          </Col>
          <Col md={{ span: 3, order: 3 }} xs={{ span: 6, order: 2 }}>
            <Nav className="flex-column">
              <Link to="/team" className="nav-link">Meet the Team</Link>
              <Link to="/locations" className="nav-link">Locations</Link>
              <Link to="/calculator" className="nav-link">Rate Calculator</Link>
              <Link to="/forms" className="nav-link">Forms</Link>
              <Link to="/order" className="nav-link">Order</Link>
              <Link to="/prices" className="nav-link">Prices</Link>
              <Link to="/contact" className="nav-link">Contact Us</Link>
              <Link to="/jobs" className="nav-link">Job Opportunities</Link>
            </Nav>
          </Col>
          <Col md={{ span: 3, order: 4 }} xs={{ span: 6, order: 4 }}>
            <div className="social">
              {document?.data.social.map((row) => (
                <a key={row.url} href={row.url || ''} target="_blank" rel="noreferrer"><span className={row.icon_class || ''} /></a>
              ))}
            </div>
            <Newsletter />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
