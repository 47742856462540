import React from 'react';
import { useSinglePrismicDocument } from '@prismicio/react';
import Container from 'react-bootstrap/Container';
import { UnderwrittersDocument } from '../../Vendors/prismic.types.generated';

export default function Underwritters() {
  const [document] = useSinglePrismicDocument<UnderwrittersDocument>('underwritters');
  return (
    <div className="underwritters">
      <Container>
        <div className="heading-with-line">
          <h1>Underwritten by the top names in the industry</h1>
          <hr />
        </div>
        <div className="logos">
          {document?.data.underwritters.map((uw) => (
            <div key={uw.url} className="uw-entry">
              <a href={uw.url || ''} target="_blank" rel="noreferrer">
                <img src={uw.logo.url || ''} alt={uw.name || ''} />
              </a>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}
