import React from 'react';

export default function Loading() {
  return (
    <div className="lds-ripple">
      <div />
      <div />
    </div>
  );
}
