import React, { useEffect, useState } from 'react';
import { Home2023Document } from '../../Vendors/prismic.types.generated';

export default function Hero({
  home,
}: {
  home: Home2023Document | undefined
}) {
  const [heroIndex, setHeroIndex] = useState(0);
  const [aminationClass, setAnimationClass] = useState('slide-in-right');
  let handles: NodeJS.Timeout[] = [];
  const heros: {
    h2: string,
    h1: string,
  }[] = [];
  if (home) {
    home.data.hero_text.forEach((hero) => {
      heros.push({
        h2: hero.title || '',
        h1: hero.title_tagline || '',
      });
    });
  }
  const animateHero = (index: number) => {
    const newIndex = (index + 1) % heros.length;
    // console.log(`rotate hero ${newIndex}`);
    handles.push(setTimeout(() => {
      setAnimationClass('slide-in-right');
      setHeroIndex(newIndex);
    }, 3000));
  };
  useEffect(() => {
    if (heros.length > 0) {
      // console.log('use effect fired');
      handles.push(setTimeout(() => {
        setAnimationClass('');
      }, 1000));
      animateHero(heroIndex);
    }
    return () => {
      handles.forEach((handle) => {
        clearTimeout(handle);
      });
      handles = [];
    };
  }, [heros]);
  return (
    <div className="hero-content">
      <div className="logo">
        <img src="/images/LLA-logo (torch only)-cropped.png" alt="Liberty Land Abstract" />
      </div>
      {heros.length > 0 && (
        <div className={`copy ${aminationClass}`}>
          <h2>{heros[heroIndex].h2}</h2>
          <h1>{heros[heroIndex].h1}</h1>
        </div>
      )}
    </div>
  );
}
