import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { StaffPageDocumentDataBodyVideoSlice } from '../../Vendors/prismic.types.generated';

function SliceVideo({ slice }: { slice: StaffPageDocumentDataBodyVideoSlice }) {
  return null;
  return (
    <div className={`slice-${slice.slice_type}`}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div
              className="video"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: slice.primary.video.html || '' }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SliceVideo;
