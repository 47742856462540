import React from 'react';
import * as RichText from '@prismicio/richtext';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import {
  StaffDocument,
  StaffPageDocumentDataBodyRowSlice,
} from '../../Vendors/prismic.types.generated';

export default function SliceRow({ slice }: { slice: StaffPageDocumentDataBodyRowSlice }) {
  const personRows = slice.items as unknown as { staff: StaffDocument }[];
  // console.log(personRows);
  return (
    <div className={`staff-row ${slice.primary.row_type}`}>
      {personRows.map((person) => (
        <div className="staff-entry" key={person.staff.uid}>
          <Link to={`/team/${person.staff.uid}`}>
            <img src={`${person.staff.data.image.url}&width=200&height=200&fit=crop`} alt={RichText.asText(person.staff.data.name)} />
          </Link>
          <div className="name">
            <Link to={`/team/${person.staff.uid}`}>
              {RichText.asText(person.staff.data.name)}
            </Link>
          </div>
          <div className="meta">
            <div className="job-title">{person.staff.data.job_title ? RichText.asText(person.staff.data.job_title) : null}</div>
            <div className="email">
              <a href={`mailto:${person.staff.data.email}`}>{person.staff.data.email}</a>
            </div>
          </div>
          <Link to={`/team/${person.staff.uid}`}><Button size="sm">Bio</Button></Link>
        </div>
      ))}
    </div>
  );
}
