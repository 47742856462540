import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Newsletter() {
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };
  const intro = 'Join our mailing list to sign up for our e-CLE credit classes';
  return (
    <div className="newsletter">
      <h2>Newsletter</h2>
      <p>{intro}</p>
      <input
        type="email"
        placeholder="Email Address"
        onClick={toggleShow}
      />
      <Modal
        show={show}
        onHide={toggleShow}
      >
        <Modal.Header closeButton>
          <h2>Newsletter Signup</h2>
        </Modal.Header>
        <Modal.Body>
          <p>{intro}</p>
          <form
            method="post"
            action="https://app.sendx.io/api/v1/form/aHldkg8wmCm67Uu7twecJL"
          >
            <Row>
              <Col>
                <input
                  id="SubscriberForm-First Name-aHldkg8wmCm67Uu7twecJL"
                  type="text"
                  name="First Name"
                  placeholder="First Name"
                  required
                />
              </Col>
              <Col>
                <input
                  id="SubscriberForm-Last Name-aHldkg8wmCm67Uu7twecJL"
                  type="text"
                  name="Last Name"
                  placeholder="Last Name"
                  required
                />
              </Col>
            </Row>
            <input
              id="SubscriberForm-Email-aHldkg8wmCm67Uu7twecJL"
              type="email"
              name="Email"
              placeholder="Email Address"
              required
            />
            <Row>
              <Col>
                <input
                  id="SubscriberForm-Company-aHldkg8wmCm67Uu7twecJL"
                  type="text"
                  name="Company"
                  placeholder="Company"
                />
              </Col>
              <Col>
                <select>
                  <option value="">Occupation</option>
                  <option value="Attorney or Paralegal">Attorney or Paralegal</option>
                  <option value="Mortgage Broker">Mortgage Broker</option>
                  <option value="Real Estate Agent">Real Estate Agent</option>
                  <option value="Title Closer">Title Closer</option>
                  <option value="Other">Other</option>
                </select>
              </Col>
            </Row>
            <Button
              type="submit"
              name="subscribe"
              value="Subscribe"
            >
              Subscribe
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Newsletter;
