/* eslint-disable react/no-array-index-key */
import React from 'react';
import * as RichText from '@prismicio/richtext';
import Modal from 'react-bootstrap/Modal';

import { PrismicRichText, usePrismicDocumentByUID } from '@prismicio/react';
import { useNavigate, useParams } from 'react-router-dom';
import { StaffDocument } from '../../Vendors/prismic.types.generated';
import Loading from '../../Layout/Loading';

function StaffModal() {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [entry, { state }] = usePrismicDocumentByUID<StaffDocument>('staff', uid || '');
  const handleClose = () => {
    navigate('/team');
  };
  if (state !== 'loaded') return (<Loading />);

  return (
    <Modal className="staff-modal" show onHide={handleClose}>
      <Modal.Body>
        <div>
          <button className="as-text close-button" type="button" onClick={handleClose}>
            <span className="fas fa-times" />
          </button>
          <div className="top-row">
            <img src={`${entry?.data.image.url}&width=200&height=200&fit=crop`} alt={RichText.asText(entry?.data.name || [])} />
            <div>
              <h1>{RichText.asText(entry?.data.name || [])}</h1>
              <div className="info">
                {entry?.data.job_title ? <div className="job-title">{RichText.asText(entry.data.job_title)}</div> : null}
                {entry?.data.email ? <div className="email"><a href={`mailto:${entry.data.email}`}>{entry.data.email}</a></div> : null}
              </div>
            </div>
          </div>
          <div className="bio">
            <PrismicRichText field={entry?.data.bio} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default StaffModal;
