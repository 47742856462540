import memoize from 'fast-memoize';

function checkGroup(
  groupsFromPrismic: string[],
  groupLeft: string[],
  groupRight: string[],
) {
  groupsFromPrismic.forEach((g) => {
    let hasGroup = false;
    if (groupLeft.indexOf(g) !== -1) hasGroup = true;
    if (groupRight.indexOf(g) !== -1) hasGroup = true;
    if (!hasGroup) console.error(`${g} is missing`);
  });
}

const memCheckGroup = memoize(checkGroup);
export default memCheckGroup;
