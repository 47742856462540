/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  CLOSING_BUY, FAMILY1, REFI_STRAIGHT, USAGE_COMMERCIAL,
} from '../RateCalculator/calcConstants';
import { ModelRateInputData } from '../RateCalculator/rateInputData.model';

const initCalculatorInput: ModelRateInputData = {
  usageType: USAGE_COMMERCIAL,
  closingType: CLOSING_BUY,
  residentialType: FAMILY1,
  refiType: REFI_STRAIGHT,
  purchase: '',
  loan: '',
  loanOld: '',
  qtyBuyers: '1',
  qtySellers: '1',
  county: '',
  city: '',
  improved: false,
};

export const sliceCalculatorInput = createSlice({
  name: 'calculatorInput',
  initialState: initCalculatorInput,
  reducers: {
    setCalculatorInput: (state, action: { payload: ModelRateInputData }) => {
      // console.log(action.payload);
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCalculatorInput,
} = sliceCalculatorInput.actions;
