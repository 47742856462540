/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { usePrismicDocumentByUID } from '@prismicio/react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import * as RichText from '@prismicio/richtext';

import {
  StaffDocument,
  StaffPageDocument,
  StaffPageDocumentDataBodyRowSlice,
  StaffPageDocumentDataBodyTitleSlice,
  StaffPageDocumentDataBodyVideoSlice,
} from '../../Vendors/prismic.types.generated';

import SliceRow from './SliceRow';
import SliceTitle from './SliceTitle';
import StaffModal from './StaffModal';
import SliceVideo from './SliceVideo';
import { BASE_URL, SITE_NAME } from '../../constant';

function Team() {
  const [videoMuted, setVideoMuted] = useState(true);
  const { uid } = useParams();
  const [document] = usePrismicDocumentByUID<StaffPageDocument>('staff_page', 'staff-page', {
    fetchLinks: [
      'staff.name',
      'staff.image',
      'staff.bio',
      'staff.email',
      'staff.phone',
      'staff.job_title',
    ],
  });
  // console.log(document);

  if (document === undefined) return (<div>Loading...</div>);

  const renderSlice = (
    // eslint-disable-next-line max-len
    slice: StaffPageDocumentDataBodyRowSlice | StaffPageDocumentDataBodyTitleSlice | StaffPageDocumentDataBodyVideoSlice,
  ) => {
    switch (slice.slice_type) {
      case 'title':
        return (<SliceTitle slice={slice} />);
      case 'row':
        return (<SliceRow slice={slice} />);
      case 'video':
        return (<SliceVideo slice={slice} />);
      default:
        return (<div>unknown type</div>);
    }
  };

  let title = `${RichText.asText(document?.data.seo_title || [])} - ${SITE_NAME}`;
  let description = `${RichText.asText(document?.data.seo_description || [])}`;
  let image = `${document.data.seo_image?.url || ''}&w=1200`;
  if (uid) {
    document?.data.body.forEach((slice) => {
      if (slice.slice_type === 'row') {
        const items = slice.items as unknown as { staff: StaffDocument }[];
        items.forEach((item) => {
          if (item.staff.uid === uid) {
            // console.log(item.staff);
            title = `${RichText.asText(item.staff.data.name)} - ${SITE_NAME}`;
            description = RichText.asText(item.staff.data.bio).replace(/\n/g, '');
            image = `${item.staff.data.image?.url || ''}&w=600&h=600`;
          }
        });
      }
    });
  }
  const toggleMusic = () => {
    setVideoMuted(!videoMuted);
  };
  return (
    <div className="page team">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={`${BASE_URL}/team${uid ? `/${uid}` : ''}`} />
      </Helmet>
      <Container>
        <h1 className="with-tag">{RichText.asText(document.data.hero)}</h1>
        <div className="h1-tag">{RichText.asText(document.data.hero_tag)}</div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="team-video">
                <button type="button" onClick={toggleMusic} className="as-text music-toggle">
                  <span className={`fas fa-volume-${videoMuted ? 'xmark' : 'high'}`} />
                </button>
                <video
                  muted={videoMuted}
                  autoPlay
                  loop
                  playsInline
                  webkit-playsinline
                >
                  <source src="https://media.llabstract.com/team.mp4" />
                </video>
              </div>
            </Col>
          </Row>
        </Container>
        {document?.data.body.map((slice) => (
          <div key={slice.id}>
            {renderSlice(slice)}
          </div>
        ))}
      </Container>
      {uid ? <StaffModal /> : null}
    </div>
  );
}

export default Team;
