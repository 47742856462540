/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import slugify from 'slugify';
import { FileUploader } from 'react-drag-drop-files';
import loadjs from 'loadjs';
import { Helmet } from 'react-helmet-async';
import { RECAPTCHA_SITE_KEY } from '../../Vendors/recaptch';

import {
  FIELD_HEADING_APPLICANT,
  IOrderField,
  IOrderGroup,
  ORDER_FIELDS,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_BUY,
  TRANSACTION_TYPE_REFI,
} from './fields';
import { BASE_URL, SITE_NAME } from '../../constant';

function Order() {
  const defaultForm: {
    [key: string]: (string | boolean)[]
  } = {};
  ORDER_FIELDS.forEach((group) => {
    group.fields.forEach((field, i) => {
      if (defaultForm[group.heading] === undefined) defaultForm[group.heading] = [];
      if (field.default) {
        defaultForm[group.heading][i] = field.default;
      } else {
        defaultForm[group.heading][i] = '';
      }
    });
  });
  const [form, setForm] = useState(defaultForm);
  const handleInputChange = (heading: string, index:number, value: string | boolean) => {
    const newForm = { ...form };
    // console.log({ heading, index, value });
    newForm[heading][index] = value;
    setForm(newForm);
  };

  useEffect(() => {
    loadjs('https://www.google.com/recaptcha/api.js', () => {});
  }, []);

  const renderField = (heading: string, field: IOrderField, index:number) => {
    const fieldName = slugify(`${heading} ${index} ${field.namePrefix || ''} ${field.label}`, { lower: true, strict: true });
    switch (field.type) {
      case 'heading':
        return (<h2>{field.label}</h2>);
      case 'file':
        return (
          <>
            <label>{form[heading][index] !== '' ? field.label : <>&nbsp;</>}</label>
            <input
              type="file"
              name={fieldName}
            />
          </>
        );
      case 'checkbox':
        return (
          <div className="checkbox-group">
            <input
              type="checkbox"
              checked={form[heading][index] as boolean}
              onClick={() => handleInputChange(heading, index, !form[heading][index])}
              name={fieldName}
            />
            <label>{field.label}</label>
          </div>
        );
      case 'textarea':
        return (
          <>
            <label>{form[heading][index] !== '' ? field.label : <>&nbsp;</>}</label>
            <textarea
              placeholder={field.label}
              value={form[heading][index] as string}
              name={fieldName}
            />
          </>
        );
      case 'radio':
        return (
          <div className="radio-group">
            <label>{field.label}</label>
            <div className="radio-options">
              {field.options?.map((option) => (
                <div key={option} className={`radio-row ${field.inputClass ? field.inputClass : ''}`}>
                  <label>{option}</label>
                  <input
                    type="radio"
                    name={fieldName}
                    value={option}
                    checked={form[heading][index] === option}
                    onChange={(e) => handleInputChange(heading, index, e.target.value)}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      default:
        return (
          <>
            <label>{form[heading][index] !== '' ? field.label : <>&nbsp;</>}</label>
            <input
              type="text"
              placeholder={`${field.label}${field.required ? '*' : ''}`}
              value={form[heading][index] as string}
              name={fieldName}
              onChange={(e) => handleInputChange(heading, index, e.target.value)}
              required={field.required}
            />
          </>
        );
    }
  };
  // console.log(form);
  const adjustedFields: IOrderGroup[] = [];
  ORDER_FIELDS.forEach((group) => {
    let skip = false;
    if (
      form[TRANSACTION_TYPE][0] === TRANSACTION_TYPE_BUY
      && group.heading === TRANSACTION_TYPE_REFI
    ) {
      skip = true;
    }
    if (
      form[TRANSACTION_TYPE][0] === TRANSACTION_TYPE_REFI
      && group.heading === TRANSACTION_TYPE_BUY
    ) {
      skip = true;
    }
    if (!skip) adjustedFields.push(group);
  });

  const simpleFields: IOrderGroup[] = [];
  ORDER_FIELDS.forEach((group) => {
    if (group.heading === FIELD_HEADING_APPLICANT) simpleFields.push(group);
  });

  const fileTypes = ['PDF'];
  const handleFileDropChange = () => {};
  const title = 'Order';
  return (
    <div className="page order">
      <Helmet>
        <title>{`${title} - ${SITE_NAME}`}</title>
        <meta property="og:title" content={title} />
        <meta property="og:url" content={`${BASE_URL}/forms`} />
      </Helmet>
      <Container>
        <Row className="justify-content-center">
          <Col lg={6}>
            <h1>{title}</h1>
            <form
              className="panel"
              acceptCharset="UTF-8"
              action="https://usebasin.com/f/ac92f08db8e3"
              encType="multipart/form-data"
              method="POST"
            >
              <div className="note">* required fields</div>
              <FileUploader
                classes="drag-drop-file"
                handleChange={handleFileDropChange}
                name="file"
                types={fileTypes}
                multiple={false}
                label="Upload Contract of Sales"
              />
              {simpleFields.map((group, j) => (
                <div key={j}>
                  <Row>
                    {group.fields.map((field, i) => (
                      <Col key={i} sm={field.size ? field.size : 12}>
                        {renderField(group.heading, field, i)}
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
              <div className="g-recaptcha" data-sitekey={RECAPTCHA_SITE_KEY} />
              <Button type="submit">Submit Order</Button>
            </form>
            <h1>Advance Order Form</h1>
            <p>Use this form if you would like to enter the full info manually.</p>
            <form
              className="panel"
              acceptCharset="UTF-8"
              action="https://usebasin.com/f/ac92f08db8e3"
              encType="multipart/form-data"
              method="POST"
            >
              <div className="note">* required fields</div>
              {adjustedFields.map((group, j) => (
                <div key={j}>
                  <h2 className={group.headingClass ? group.headingClass : ''}>{group.heading}</h2>
                  {group.notes ? (
                    <p>{group.notes}</p>
                  ) : null}
                  <Row>
                    {group.fields.map((field, i) => (
                      <Col key={i} sm={field.size ? field.size : 12}>
                        {renderField(group.heading, field, i)}
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
              <div className="g-recaptcha" data-sitekey={RECAPTCHA_SITE_KEY} />
              <Button type="submit">Submit Order</Button>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Order;
