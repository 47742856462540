export const TRANSACTION_TYPE = 'Transaction Type';
export const TRANSACTION_TYPE_REFI = 'Refinance';
export const TRANSACTION_TYPE_BUY = 'Purchase';

export interface IOrderField {
  label: string,
  required?: boolean,
  size?: number,
  type?: string,
  inputClass?: string,
  default?: string | boolean,
  options?: string[],
  namePrefix?: string
}
export interface IOrderGroup {
  heading: string,
  headingClass?: string,
  notes?: string
  fields: IOrderField[],
}

export const FIELD_HEADING_APPLICANT = 'Applicant\'s Info';
export const ORDER_FIELDS: IOrderGroup[] = [
  {
    heading: FIELD_HEADING_APPLICANT,
    fields: [
      {
        label: 'Applicant\'s name',
        required: true,
        size: 6,
      },
      {
        label: 'Company',
        required: true,
        size: 6,
      },
      {
        label: 'Email',
        required: true,
      },
      {
        label: 'Address',
        required: true,
      },
      {
        label: 'Phone',
        required: true,
        size: 6,
      },
      {
        label: 'Fax',
        size: 6,
      },
    ],
  },
  {
    heading: 'Property Info',
    fields: [
      {
        label: 'Property Address',
        required: true,
      },
      {
        label: 'District',
        size: 3,
      },
      {
        label: 'Section',
        size: 3,
      },
      {
        label: 'Block',
        size: 3,
      },
      {
        label: 'Lot',
        size: 3,
      },
      {
        label: 'Property Type',
        type: 'radio',
        options: [
          '1-3 Dwelling',
          '4 Dwelling',
          'Commercial',
          'Condominium',
          'Vacant',
          'Co-Op',
        ],
      },
    ],
  },
  {
    heading: TRANSACTION_TYPE,
    headingClass: 'hide',
    fields: [
      {
        label: 'Transaction Type',
        inputClass: 'as-h3',
        size: 12,
        type: 'radio',
        default: TRANSACTION_TYPE_BUY,
        options: [
          TRANSACTION_TYPE_BUY,
          TRANSACTION_TYPE_REFI,
        ],
      },
    ],
  },
  {
    heading: TRANSACTION_TYPE_REFI,
    headingClass: 'hide',
    fields: [
      {
        label: 'Loan Amount ($)',
        required: true,
      },
      {
        label: 'Borrower\'s Name(s)',
        required: true,
      },
      {
        label: 'Lender\'s Name',
        required: true,
      },
      {
        label: 'Lender\'s Attorney',
        type: 'heading',
      },
      {
        label: 'Name',
        size: 6,
        required: true,
        namePrefix: 'Lender\'s Attorney',
      },
      {
        label: 'Firm',
        size: 6,
        required: true,
        namePrefix: 'Lender\'s Attorney',
      },
      {
        label: 'Email',
        required: true,
        namePrefix: 'Lender\'s Attorney',
      },
      {
        label: 'Address',
        required: true,
        namePrefix: 'Lender\'s Attorney',
      },
      {
        label: 'Tel',
        required: true,
        size: 6,
        namePrefix: 'Lender\'s Attorney',
      },
      {
        label: 'Fax',
        size: 6,
        namePrefix: 'Lender\'s Attorney',
      },
    ],
  },
  {
    heading: TRANSACTION_TYPE_BUY,
    headingClass: 'hide',
    fields: [
      {
        label: 'Purchase Amount ($)',
        required: true,
      },
      {
        label: '1st Loan Amount ($)',
        size: 6,
      },
      {
        label: '2nd Loan Amount ($)',
        size: 6,
      },
      {
        label: 'Purchaser\'s Name(s)',
        required: true,
      },
      {
        label: 'Seller\'s Name(s)',
        required: true,
      },
      {
        label: 'Lender\'s Name',
      },
      {
        label: 'Seller\'s Attorney',
        type: 'heading',
      },
      {
        label: 'Name',
        required: true,
        size: 6,
        namePrefix: 'Seller\'s Attorney',
      },
      {
        label: 'Firm',
        required: true,
        size: 6,
        namePrefix: 'Seller\'s Attorney',
      },
      {
        label: 'Email',
        required: true,
        namePrefix: 'Seller\'s Attorney',
      },
      {
        label: 'Address',
        required: true,
        namePrefix: 'Seller\'s Attorney',
      },
      {
        label: 'Tel',
        required: true,
        size: 6,
        namePrefix: 'Seller\'s Attorney',
      },
      {
        label: 'Fax',
        size: 6,
        namePrefix: 'Seller\'s Attorney',
      },
      {
        label: 'Purchaser\'s Attorney',
        type: 'heading',
      },
      {
        label: 'Name',
        required: true,
        size: 6,
        namePrefix: 'Purchaser\'s Attorney',
      },
      {
        label: 'Firm',
        required: true,
        size: 6,
        namePrefix: 'Purchaser\'s Attorney',
      },
      {
        label: 'Email',
        required: true,
        namePrefix: 'Purchaser\'s Attorney',
      },
      {
        label: 'Address',
        required: true,
        namePrefix: 'Purchaser\'s Attorney',
      },
      {
        label: 'Tel',
        required: true,
        size: 6,
        namePrefix: 'Purchaser\'s Attorney',
      },
      {
        label: 'Fax',
        size: 6,
        namePrefix: 'Purchaser\'s Attorney',
      },
      {
        label: 'Lender\'s Attorney',
        type: 'heading',
      },
      {
        label: 'Name',
        size: 6,
        namePrefix: 'Lender\'s Attorney',
      },
      {
        label: 'Firm',
        size: 6,
        namePrefix: 'Lender\'s Attorney',
      },
      {
        label: 'Email',
        namePrefix: 'Lender\'s Attorney',
      },
      {
        label: 'Address',
        namePrefix: 'Lender\'s Attorney',
      },
      {
        label: 'Tel',
        size: 6,
        namePrefix: 'Lender\'s Attorney',
      },
      {
        label: 'Fax',
        size: 6,
        namePrefix: 'Lender\'s Attorney',
      },
      {
        label: 'TOEPP Policy',
        type: 'heading',
      },
      {
        label: 'Check here if you require a TOEPP OWNER’S POLICY',
        type: 'checkbox',
        default: false,
      },
    ],
  },
  {
    heading: 'File Upload',
    notes: 'Please upload documents that would assist with processing this order (ie: contract of sale, loan commitment, 1003, etc…). Max file size, 5MB; larger files will prevent the order from reaching us',
    fields: [
      {
        label: 'Attached File',
        type: 'file',
      },
      {
        label: 'Attached File',
        type: 'file',
      },
    ],
  },
];
