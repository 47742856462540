import memoize from 'fast-memoize';
import { DocumentsDocument } from '../../../Vendors/prismic.types.generated';

function docsByGroup(documents: DocumentsDocument[], search: string) {
  const entriesByGroup: { [key: string]: DocumentsDocument[] } = {};
  documents.forEach((entry) => {
    if (entry.data.group) {
      if (entriesByGroup[entry.data.group] === undefined) entriesByGroup[entry.data.group] = [];
      const needle = search.toLowerCase();
      if (needle !== '') {
        if (entry?.data?.title?.match(new RegExp(needle, 'i'))) {
          // console.log(entry.data.title);
          entriesByGroup[entry.data.group].push(entry);
        }
      } else {
        entriesByGroup[entry.data.group].push(entry);
      }
    }
  });
  /*
  documents.forEach((entry) => {
    // console.log(entry.data.group);
    if (entry.data.group) {
      if (entriesByGroup[entry.data.group] === undefined) entriesByGroup[entry.data.group] = [];
      entriesByGroup[entry.data.group].push(entry);
    }
  });
  */
  return entriesByGroup;
}

const memDocsByGroup = memoize(docsByGroup);
export default memDocsByGroup;
