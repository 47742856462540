/* eslint-disable react/no-array-index-key */
import {
  PrismicRichText, PrismicText, useAllPrismicDocumentsByType,
  usePrismicDocumentByUID,
} from '@prismicio/react';
import moment from 'moment-timezone';
import * as prismic from '@prismicio/client';
import { asText } from '@prismicio/client';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { CoursePageDocument, CoursesDocument } from '../../Vendors/prismic.types.generated';

export default function Courses() {
  const yesterdayString = moment(moment().format('YYYY-MM-DD')).subtract(1, 'days').format('YYYY-MM-DD');
  const [
    courses,
    { state: prismicState },
  ] = useAllPrismicDocumentsByType<CoursesDocument>('courses', {
    orderings: { field: 'my.documents.course_date' },
    filters: [
      prismic.filter.dateAfter('my.courses.course_date', yesterdayString),
    ],
  });

  const [coursePageSettings, { state: settingState }] = usePrismicDocumentByUID<CoursePageDocument>('course_page', 'course-page-settings');
  // if (prismicState === 'failed') return <div>{prismicError?.message}</div>;
  if (prismicState !== 'loaded') return <div>Loading...</div>;
  if (settingState !== 'loaded') return <div>Loading...</div>;
  if (!courses) return <div>No courses found</div>;
  const futureCourses = courses.filter(
    (course) => moment(course.data.course_date).isAfter(moment()),
  );
  const featureCourse = futureCourses.find((course) => course.data.featured_course);
  const otherCourses = futureCourses
    .filter((course) => course.id !== featureCourse?.id)
    .sort((a, b) => {
      const dateA = moment(a.data.course_date);
      const dateB = moment(b.data.course_date);
      if (dateA.isBefore(dateB)) return -1;
      if (dateA.isAfter(dateB)) return 1;
      return 0;
    });
  return (
    <div className="courses">
      {featureCourse && (
        <div
          className="hero"
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url(${featureCourse.data.featured_background_image.url}) lightgray 50% / cover no-repeat`,
          }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col xl={9} sm={12}>
                <h2><PrismicText field={featureCourse?.data.featured_tag} /></h2>
                <h1><PrismicText field={featureCourse?.data.featured_title} /></h1>
                <PrismicRichText field={featureCourse?.data.featured_description} />
                <a href={featureCourse?.data.registation_link || ''} target="_blank" className="btn btn-secondary" rel="noreferrer">Register Now</a>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      <div className="page">
        <Container>
          <Row>
            <Col xs={12}>
              <h1>Courses</h1>
            </Col>
          </Row>
          <div className="course-list">
            {otherCourses.length > 0 ? (
              <Row className="justify-content-center">
                {otherCourses.map((course) => (
                  <Col xl={4} md={6} sm={12} key={course.id}>
                    <div className="course-card">
                      <div>
                        <img
                          src={`${course.data.course_image.url}&width=560&height=315&fit=crop`}
                          alt={asText(course?.data.featured_title)}
                        />
                      </div>
                      <div>
                        <div className="categories">
                          {course.data.categories.map((category, i) => (<span className="category" key={i}>{category.category}</span>))}
                        </div>
                        <h2 className="course-title"><PrismicText field={course.data.title} /></h2>
                        <div>
                          <b>Date: </b>
                          {course.data.date_tba ? 'TBD' : moment(course.data.course_date).tz('America/New_York').format('MMM Do hh:mma')}
                        </div>
                        {course.data.location && course.data.location !== '' ? (
                          <div>
                            <b>Location: </b>
                            {course.data.location}
                          </div>
                        ) : <div>&nbsp;</div>}
                      </div>
                      <div className="course-description">
                        <PrismicText field={course.data.description} />
                      </div>
                      <div>
                        {course.data.registation_link === '' || course.data.registation_link === null ? (
                          <Button disabled>
                            Coming Soon
                          </Button>
                        ) : (
                          <a href={course.data.registation_link || ''} target="_blank" className="btn btn-primary" rel="noreferrer">
                            Register
                          </a>
                        )}
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>

            ) : (
              <div className="no-courses"><PrismicRichText field={coursePageSettings?.data.no_courses_message} /></div>
            )}
          </div>
          <div className="heading-with-line">
            <h2>
              <PrismicText field={coursePageSettings?.data.sponsor_heading} />
            </h2>
            <hr />
          </div>
          <Row className="justify-content-center">
            {coursePageSettings?.data.sponsors.map((sponsor, i) => (
              <Col md={2} sm={3} xs={4} key={i}>
                <a href={sponsor.sponsor_url || ''} target="_blank" rel="noreferrer"><img src={sponsor.sponsor_logo.url || ''} alt={sponsor.sponsor_name || ''} /></a>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

    </div>
  );
}
