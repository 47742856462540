import React from 'react';
import * as RichText from '@prismicio/richtext';
import {
  StaffPageDocumentDataBodyTitleSlice,
} from '../../Vendors/prismic.types.generated';

export default function SliceTitle({ slice }: { slice: StaffPageDocumentDataBodyTitleSlice }) {
  return (
    <h2>{RichText.asText(slice.primary.department)}</h2>
  );
}
